.home {
  /* padding: 0 15%; */
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}
.home > .content {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}
.home .to-backward {
  display: flex;
  justify-content: flex-end;
  font-family: "Kanit", sans-serif;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.home .to-backward > svg {
  margin-right: 10px;
}
.home .to-backward > span {
  font-weight: bold;
  color: black;
}
.home .header {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 18px 36px 0 rgba(0, 0, 0, 0.05);
  padding: 70px 0;
}
.home .header > svg {
  margin: 0 60px;
}

@media only screen and (max-width: 850px) {
  .home .header > svg {
    margin: 10px 60px;
    width: 100vw;
  }
}

.home .header-title > h2 {
  font-weight: 500;
  text-align: center;
  font-family: "Kanit", sans-serif;
  font-size: 3em;
  margin: 60px 0;
}
.home .header-title > h2 > span {
  color: #ec1f24;
}
.home .reason {
  background-color: #ec1f24;
  font-family: "Kanit", sans-serif;
  color: white;
  border-radius: 10px;
  padding: 30px 30px 40px;
}
.home .reason > h3 {
  font-weight: 200;
  margin-top: 0;
  font-size: 1.7em;
  font-family: "Dela Gothic One", cursive !important;
  margin-bottom: 15px;
}
.home .reason > p {
  margin: 0;
  line-height: 1.5em;
  font-size: 1.1em;
}
.home .guide {
  background-color: #f5f5f5;
  font-family: "Kanit", sans-serif;
  color: black;
  border-radius: 10px;
  padding: 30px;
  margin-top: 50px;
}
.home .guide > h3 {
  font-weight: 200;
  margin-top: 0;
  font-size: 1.7em;
  font-family: "Dela Gothic One", cursive !important;
  margin-bottom: 15px;
}
.home .guide > p {
  margin: 0;
  line-height: 1.5em;
  font-size: 1.1em;
}
.home .guide .guide-list-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.home .guide .guide-list-item-no {
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ec1f24;
  color: white;
  justify-content: center;
  align-items: center;
  font-weight: 200;
  margin-right: 10px;
  font-family: "Dela Gothic One", cursive !important;
}
.home .guide .guide-list-item > p {
  margin: 10px;
  font-size: 1.1em;
  flex: 1;
}
.home .footer {
  text-align: center;
  font-family: "Dela Gothic One", cursive !important;
  margin-top: 80px;
  margin-bottom: 150px;
  position: relative;
}
.home .footer > img.chicken {
  position: absolute;
  top: -115%;
  right: -14%;
  max-width: 476px;
}
@media only screen and (max-width: 1670px) {
  .home .footer > img.chicken {
    position: relative;
    display: block;
    top: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 1670px) {
  .home .footer > img.chicken {
    width: 100%;
  }
}
.home .footer > h2 {
  font-weight: 400;
  margin-top: -5px;
  font-size: 2.2em;
}
.home .footer > h3 {
  font-weight: 300;
  margin-top: 50px;
  margin-bottom: 0px;
  font-size: 1.5em;
}
.home .footer > button {
  background-color: #ec1f24;
  border-radius: 10px;
  color: white;
  font-weight: 300;
  font-family: "Dela Gothic One", cursive !important;
  border: none;
  padding: 20px 30px;
  font-size: 1.4em;
  margin-top: 20px;
  cursor: pointer;
}

.contact {
  border-top: 1px solid gray;
  font-family: "Dela Gothic One", cursive !important;
}
.contact > h2 {
  font-weight: 400;
  margin-top: 0px;
  font-size: 2em;
  color: #e03b42;
  text-align: center;
  margin: 30px 0;
}
.contact > p {
  font-weight: 400;
  margin-top: 0px;
  font-size: 0.8em;
  color: #ecab47;
  text-align: center;
  margin: 0;
}
.contact > .social__list {
  list-style-type: none;
  margin-top: 30px;
  margin-bottom: 50px;
  padding-left: 0;
  display: flex;
  justify-content: center;
}
.contact > .social__list > li {
  margin: 0 8px;
}

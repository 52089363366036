.content {
  background-repeat: no-repeat;
  background-size: cover;
}
.content > .content-body {
  padding: 0 15%;
}
.wallet-connection-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}
.wallet-connection-button > h2{
  font-family: "Dela Gothic One", cursive !important;
  font-weight: 400;
}
.wallet-info-panel {
  background-color: #e9e9e9;
  font-family: "Dela Gothic One", cursive !important;
  color: black;
  border-radius: 10px;
  padding: 30px;
  margin-top: 20px;
  margin-bottom: 50px;
  overflow: hidden;
}

.wallet-info-panel > h2 {
  margin: 0;
  font-weight: 300;
  font-size: 2em;
}

.wallet-info-panel > p{
  font-weight: 300;
}

.wallet-info-panel > p > span{
  margin-right: 10px;
  font-weight: bold;
  font-size: 1.2em;

}
.adapter-react-button {
  border-radius: 7px !important;
  background-color: #ec1f24;
}
.adapter-react-button img {
  border: 1px solid white !important;
  border-radius: 5px;
}
.adapter-react-button:hover {
  background-color: #e84b53 !important;
}
.contact {
  padding: 0 15%;
  border-top: 1px solid gray;
  font-family: "Dela Gothic One", cursive !important;
}
.contact > h2 {
  font-weight: 400;
  margin-top: 0px;
  font-size: 2em;
  color: #e03b42;
  text-align: center;
  margin: 30px 0;
}
.contact > p {
  font-weight: 400;
  margin-top: 0px;
  font-size: 0.8em;
  color: #ecab47;
  text-align: center;
  margin: 0;
}
.contact > .social__list {
  list-style-type: none;
  margin-top: 30px;
  margin-bottom: 50px;
  padding-left: 0;
  display: flex;
  justify-content: center;
}
.contact > .social__list > li {
  margin: 0 8px;
}
